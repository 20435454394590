import Image from 'next/image'

export const WeBringYouGet = (): JSX.Element => {
  return (
    <section
      id="we-bring-you-get"
      className="flex flex-col justify-center px-4 py-12 md:flex-row gap-14 lg:px-12 lg:w-full"
    >
      <div>
        <h3 className="mb-6 text-center fora-text-callout-4 md:fora-text-callout-7">
          What we bring
        </h3>
        <Image
          src="https://media.foratravel.com/image/upload/v1718198896/we-bring_lejaf3.png"
          alt="What we bring"
          width={312}
          height={481}
          quality={90}
          className="m-auto"
        />
      </div>
      <div className="w-1 h-[434px] border-l-2 border-darkShell hidden md:block" />
      <div>
        <h3 className="mb-6 text-center fora-text-callout-4 md:fora-text-callout-7">
          What you get
        </h3>
        <Image
          src="https://media.foratravel.com/image/upload/v1718198896/you-get_spoc6j.png"
          alt="What you get"
          width={321}
          height={481}
          quality={90}
          className="m-auto"
        />
      </div>
    </section>
  )
}
