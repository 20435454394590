import europe from '/public/images/destinations/europe.webp'
import asia from '/public/images/destinations/asia.webp'
import northAmerica from '/public/images/destinations/north-america.webp'
import centralAmerica from '/public/images/destinations/central-america.webp'
import southAmerica from '/public/images/destinations/south-america.webp'
import caribbean from '/public/images/destinations/caribbean.webp'
import oceania from '/public/images/destinations/oceania.webp'
import africa from '/public/images/destinations/africa.webp'
import middleEast from '/public/images/destinations/middle-east.webp'
import polar from '/public/images/destinations/polar.webp'

export const DestinationContinentsData = [
  {
    name: 'Europe',
    href: '/travel/destinations/europe',
    imageSrc: europe.src,
  },
  {
    name: 'Asia',
    href: '/travel/destinations/asia',
    imageSrc: asia.src,
  },
  {
    name: 'North America',
    href: '/travel/destinations/north-america',
    imageSrc: northAmerica.src,
  },
  {
    name: 'Central America',
    href: '/travel/destinations/central-america',
    imageSrc: centralAmerica.src,
  },
  {
    name: 'South America',
    href: '/travel/destinations/south-america',
    imageSrc: southAmerica.src,
  },
  {
    name: 'Caribbean',
    href: '/travel/destinations/caribbean',
    imageSrc: caribbean.src,
  },
  {
    name: 'Oceania',
    href: '/travel/destinations/oceania',
    imageSrc: oceania.src,
  },
  {
    name: 'Africa',
    href: '/travel/destinations/africa',
    imageSrc: africa.src,
  },
  {
    name: 'Middle East',
    href: '/travel/destinations/middle-east',
    imageSrc: middleEast.src,
  },
  {
    name: 'Polar',
    href: '/travel/destinations/polar',
    imageSrc: polar.src,
  },
]
