import { useEffect, useRef } from 'react'

/* Call callback if window width crosses any of the breakpoints */
export const useCrossBreakpoints = ({
  breakpoints,
  callback,
}: {
  breakpoints: number[]
  callback: () => void
}) => {
  const windowWidth = useRef<number>(0)

  useEffect(() => {
    const handleResize = (entry: ResizeObserverEntry) => {
      const prev = windowWidth.current
      const curr = entry.contentRect.width
      const crossings = breakpoints.map(
        (breakpoint) =>
          (prev <= breakpoint && curr >= breakpoint) ||
          (prev >= breakpoint && curr <= breakpoint)
      )
      if (crossings.some((c) => c)) callback()
      windowWidth.current = curr
    }

    const observer = new ResizeObserver((entries) =>
      entries.forEach(handleResize)
    )
    if (document?.body) observer.observe(document.body)
    return () => {
      observer.disconnect()
    }
  }, [breakpoints, callback])
}
