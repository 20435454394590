import { useEffect, useRef, useState } from 'react'

type RollingDigitsProps = {
  num: number
  ariaHidden?: boolean
  height?: number // rem
  fontSize?: number // rem
  transitionDuration?: number // ms
  format: RollingDigitsFormat
  className?: string
  mobileHeight?: number //rem
  mobileFontSize?: number //rem
}

export enum RollingDigitsFormat {
  USD = 'USD',
  PERCENTAGE = 'Percentage',
}

const DIGITS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

// Formatter for currency (US dollars)
const USD: Intl.NumberFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
})

// Formatter for percentages
const toPercentage = (num: number): string => `${num}%`

// Creates a new HTML element with specified type, class, and optional inner text
const createElement = (
  type: string,
  className: string,
  text?: string
): HTMLElement => {
  const element: HTMLElement = document.createElement(type)
  element.className = className
  if (text !== undefined) element.innerText = text
  return element
}

// Creates a character element ("$", ",")
const createCharacter = (character: string): HTMLElement =>
  createElement('span', 'character', character)

// Extracts a specific digit from the number by its index
const getDigitByIndex = (index: number, num: number): number =>
  parseInt(num.toString()[index])

// Number of times the track spins
const determineIterations = (index: number) => index + 2

/**
 * Displays a USD or percentage amount. If the amount changes, the digits of the number will rotate to the new amount.
 * Each digit has its own track with the numbers 0-9.
 */
export const RollingDigits = ({
  num,
  ariaHidden,
  height = 3.3,
  mobileHeight = 1.8,
  mobileFontSize = 1.5,
  fontSize = 3,
  transitionDuration = 200,
  format = RollingDigitsFormat.USD,
  className,
}: RollingDigitsProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)
  const [{ fromNum, toNum }, setNumbers] = useState({
    fromNum: -1,
    toNum: num,
  })

  useEffect(() => {
    setNumbers((prev) => ({ fromNum: prev.toNum, toNum: num }))
  }, [num])

  const getTracks = (): HTMLElement[] => {
    const nodeList: NodeListOf<HTMLElement> | undefined =
      ref.current?.querySelectorAll('.digit > .digit-track')
    return Array.from(nodeList ?? [])
  }

  // Creates a digit element, which contains a track (0-9) that it can rotate through
  const createDigit = (index: number): HTMLElement => {
    const digitElement: HTMLElement = createElement('span', 'digit'),
      trackElement: HTMLElement = createElement('span', 'digit-track')

    const iterations = determineIterations(index)
    for (let i = 0; i < iterations; i++)
      for (const digit of DIGITS)
        trackElement.appendChild(
          createElement('span', 'track-digit', digit.toString())
        )

    trackElement.style.transitionDuration = `${transitionDuration}ms`
    digitElement.appendChild(trackElement)
    return digitElement
  }

  // Sets up tracks
  const setUpTracks = (): void => {
    if (!ref.current) return
    let index = 0
    const formatFn =
      format === RollingDigitsFormat.USD ? USD.format : toPercentage
    const toDisplay = formatFn(toNum)
    const fromDisplay = formatFn(fromNum)

    // If number's length has changed, rebuild the tracks
    if (fromNum === -1 || toDisplay.length !== fromDisplay.length) {
      ref.current.innerHTML = ''
      for (const character of toDisplay) {
        const element: HTMLElement = isNaN(parseInt(character))
          ? createCharacter(character)
          : createDigit(index++)
        ref.current.appendChild(element)
      }
    }
  }

  // Initialize the tracks with the previous number
  const initialize = () => {
    getTracks().forEach((track, index) => {
      const activeDigit = getDigitByIndex(index, fromNum) // Start at end of track
      track.style.transitionDuration = '0ms' // Don't animate
      track.style.transform = `translate3d(0,${activeDigit * -1 * height}rem,0)`
      void track.offsetWidth
    })
  }

  // Animate the tracks to the new number
  const animate = () => {
    getTracks().forEach((track, index, array) => {
      const iterations = determineIterations(index)
      const activeDigit =
        getDigitByIndex(index, toNum) + (iterations - 1) * DIGITS.length // End at beginning of track
      const duration = transitionDuration + 40 * (array.length - index) // First digits end last
      track.style.transitionDuration = `${duration}ms` // Animate
      track.style.transform = `translate3d(0,${activeDigit * -1 * height}rem,0)`
    })
  }

  useEffect(() => {
    setUpTracks()
    initialize()
    animate()
  }, [fromNum, toNum, height, fontSize]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <p
        ref={ref}
        className={`flex overflow-hidden text-center w-max ${className}`}
        aria-hidden={!!ariaHidden}
      />

      <style jsx>{`
        p {
          height: ${mobileHeight}rem;
          line-height: ${mobileHeight}rem;
          font-size: ${mobileFontSize}rem;
        }

        p > :global(.digit) > :global(.digit-track) :global(.track-digit) {
          height: ${height}rem;
        }

        p > :global(.digit) {
          width: 1ch;
          position: relative;
          overflow: hidden;
        }

        p > :global(.digit) > :global(.digit-track) {
          width: 100%;
          height: max-content;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: absolute;
          left: 0%;
          top: 0%;
          transform: translate3d(0, 0, 0);
          transition: transform 3000ms cubic-bezier(0.07, 0.7, 0, 1.01);
        }
        @media screen and (min-width: 1024px) {
          p {
            height: ${height}rem;
            line-height: ${height}rem;
            font-size: ${fontSize}rem;
          }
        }
      `}</style>
    </>
  )
}
