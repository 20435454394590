import Image from 'next/legacy/image'
import { DestinationContinentsData } from '@/utils/fixtures/DestinationContinentsData'
import { imageLoader } from '@/utils/ImageLoaders'

const DestinationRow = ({ destinations, index }) => {
  return (
    <ul className="flex flex-wrap gap-6 mb-6 md:flex-nowrap" data-name="row">
      {destinations.map((destination) => (
        <DestinationBox
          key={destination.name}
          destination={destination.name}
          href={destination.href}
          imageSrc={destination.imageSrc}
          width={index % 2 ? '3' : '2'}
        />
      ))}
    </ul>
  )
}

export const DestinationBox = ({ destination, imageSrc, href, width }) => {
  const setWidth = {
    '2': 'md:w-1/2',
    '3': 'md:w-1/3',
    category: 'category',
  }
  return (
    <li
      className={`relative cursor-pointer w-full ${setWidth[width]} related-destination-box group`}
    >
      <a href={href}>
        <h2 className="font-sans text-[48px] text-white leading-tight absolute z-20 flex items-center justify-center w-full h-full px-4 text-center">
          {destination}
        </h2>
        <Image
          className="transition duration-500 origin-center transform group-hover:scale-105"
          loader={({ src }) =>
            imageLoader({
              src: src,
              width: 703,
              quality: 90,
            })
          }
          src={imageSrc}
          alt={destination}
          layout="responsive"
          objectFit="cover"
          objectPosition="center"
          width={703}
          height={437}
          sizes="90vw"
        />
      </a>
    </li>
  )
}
const DestinationContinents = (): JSX.Element => {
  const rows = [
    DestinationContinentsData.slice(0, 2),
    DestinationContinentsData.slice(2, 5),
    DestinationContinentsData.slice(5, 7),
    DestinationContinentsData.slice(7),
  ]

  return (
    <section>
      {rows.map((rowDestinations, index) => (
        <DestinationRow
          key={index}
          destinations={rowDestinations}
          index={index}
        />
      ))}
    </section>
  )
}
export default DestinationContinents
