import image1 from '/public/images/commission-calculator/commission-calculator-1.jpg'
import image2 from '/public/images/commission-calculator/commission-calculator-2.jpg'
import image3 from '/public/images/commission-calculator/commission-calculator-3.jpg'
import image4 from '/public/images/commission-calculator/commission-calculator-4.jpg'

export interface Commission {
  title: string
  commission: number
  image: string
  altText: string
  bookingCost?: number
}

export const Commissions: Commission[] = [
  {
    title: 'Your best friend’s hotel staycation',
    commission: 0.1,
    image: image1.src,
    altText: 'Furnished room',
    bookingCost: 1000,
  },
  {
    title: 'Your parents’ cruise',
    commission: 0.18,
    image: image2.src,
    altText: 'Beach with cruise',
    bookingCost: 5000,
  },
  {
    title: 'Your colleague’s safari ',
    commission: 0.16,
    image: image3.src,
    altText: 'Giraffe outside vehicle',
    bookingCost: 15000,
  },
  {
    title: 'Your custom adventure',
    commission: 0.12,
    image: image4.src,
    altText: 'Woman sitting on holiday home in Italy, Borgo Gallana',
  },
]
