import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import Image from 'next/legacy/image'
import { RollingDigits, RollingDigitsFormat } from '../design/RollingDigits'
import { Tab, Tabs } from '../ui/Tabs'
import ForaSwiper from '../swiper/ForaSwiper'
import {
  Commission,
  Commissions,
} from '@/utils/fixtures/CommissionCalculatorData'
import styles from '../swiper/components/commission-calculator.module.css'
import { useCrossBreakpoints } from 'hooks/useCrossBreakpoints'

const BOOKING_COST_ID = 'booking-cost'
const DIGIT_HEIGHT = 3 // rem
const DIGIT_FONT_SIZE = 3 // rem
const MOBILE_DIGIT_HEIGHT = 1.5 // rem
const MOBILE_DIGIT_FONT_SIZE = 1.5 // rem
const MAX_INPUT = 9999999
const MIN_INPUT = 0

export const CommissionCalculator = (): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [bookingCost, setBookingCost] = useState<number>()
  const activeCommission = Commissions[activeIndex]
  const commissionEarned = (bookingCost ?? 0) * activeCommission.commission
  const youKeep = commissionEarned * 0.7

  useEffect(() => {
    setBookingCost(Commissions[activeIndex].bookingCost)
  }, [activeIndex])

  // Reset to first slide at breakpoints
  useCrossBreakpoints({
    breakpoints: [640, 1024],
    callback: () => setActiveIndex(0),
  })

  return (
    <section
      id="commission-calculator"
      className="px-4 py-12 lg:px-12 bg-darkSand lg:w-full"
    >
      <h2 className="fora-text-h5">
        Select a booking example to see how much you could earn
      </h2>
      <div className="mt-8">
        <div className="block lg:hidden">
          <CarouselControls
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        </div>
        <div className="hidden lg:block">
          <TabsControls
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        </div>
      </div>

      <div aria-hidden className="grid place-items-center place-content-center">
        <hr className="w-screen my-4 border-t border-sand lg:border-0 lg:my-3" />
      </div>

      <div
        className="flex flex-col space-y-4 lg:space-y-0 lg:space-x-8 lg:flex-row"
        data-name="commission-results"
      >
        {[
          { label: 'Booking Cost', value: bookingCost, format: RollingDigitsFormat.USD }, // prettier-ignore
          { label: 'Commission Rate *', value: activeCommission.commission * 100, format: RollingDigitsFormat.PERCENTAGE }, // prettier-ignore
          { label: 'Commission Earned', value: commissionEarned, format: RollingDigitsFormat.USD }, // prettier-ignore
          { label: 'You Keep 70%', value: youKeep, format: RollingDigitsFormat.USD }, // prettier-ignore
        ].map(({ label, value, format }, index) => {
          const useInput = activeIndex === Commissions.length - 1 && index === 0
          const labelClassName =
            'whitespace-nowrap uppercase md:fora-text-eyebrow-2 md:mb-4 fora-text-eyebrow-4 pr-4 min-w-[143px] lg:min-w-[auto] lg:min-w-auto'
          return (
            <div
              key={index}
              className="flex lg:flex-col w-[311px] h-[25px] lg:h-auto lg:w-[216px] items-center lg:items-start"
              data-name={`commission-result-${index}`}
            >
              {useInput ? (
                <label htmlFor={BOOKING_COST_ID} className={labelClassName}>
                  {label}
                </label>
              ) : (
                <>
                  <p className={labelClassName} data-name="result-label">
                    {label}
                  </p>
                  <p className="sr-only" data-name="result">
                    ${value}
                  </p>
                </>
              )}
              <div
                className={`w-full max-w-[168px] lg:max-w-[216px] py-1 lg:py-0 border-b lg:border-b-2 transition-colors duration-100 ease-in-out ${
                  useInput ? 'border-blackSand' : 'border-transparent'
                }`}
              >
                {useInput ? (
                  <BookingCost value={bookingCost} setValue={setBookingCost} />
                ) : (
                  <RollingDigits
                    num={value ?? 0}
                    ariaHidden
                    height={DIGIT_HEIGHT}
                    fontSize={DIGIT_FONT_SIZE}
                    format={format}
                    className="scale-[calc(2/3)] origin-left lg:scale-100"
                  />
                )}
              </div>
            </div>
          )
        })}
      </div>

      <p
        className="mt-2 lg:mt-6 fora-text-body-3 text-darkStone"
        data-name="footnote"
      >
        * Commission levels differ by travel partner, typically ranging from
        10-25% and averaging ~12%. Most of that commission (70%) goes straight
        to you, the advisor. The remainder supports Fora&apos;s
        behind-the-scenes work invoicing partners and chasing commission checks
        to ultimately ensure timely payment to our advisors.
      </p>
    </section>
  )
}

const BookingCost = ({
  value,
  setValue,
  className,
}: {
  value?: number
  setValue: Dispatch<SetStateAction<number | undefined>>
  className?: string
}): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null)
  const handleChange = (event) => {
    const inputValue = event.target.value.replace(/[^\d]/g, '')
    const number = inputValue ? parseInt(inputValue, 10) : undefined
    if (number && (number > MAX_INPUT || number < MIN_INPUT)) return
    setValue(number)
  }

  const handleKeyDown = (event) =>
    !/[0-9]/.test(event.key) &&
    event.key !== 'Backspace' &&
    event.preventDefault()

  const displayValue = value?.toLocaleString() ?? ''

  // Autofocus on desktop
  useEffect(() => {
    const windowWidth = window?.innerWidth
    if (ref.current && windowWidth >= 1024) ref.current.focus()
  }, [])

  return (
    <div className={`flex ${className}`}>
      <span className="w-[1ch] font-sans font-medium">$</span>
      <input
        ref={ref}
        type="text"
        id={BOOKING_COST_ID}
        value={displayValue}
        onChange={handleChange}
        placeholder="Enter a dollar amount"
        className="w-full font-sans font-medium outline-none bg-darkSand"
        onKeyDown={handleKeyDown}
      />
      <style jsx>{`
        input,
        span {
          height: ${MOBILE_DIGIT_HEIGHT}rem;
          line-height: ${MOBILE_DIGIT_HEIGHT}rem;
          font-size: ${MOBILE_DIGIT_FONT_SIZE}rem;
        }

        input::placeholder {
          font-size: 0.65rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.4;
          letter-spacing: 0.02em;
          text-transform: uppercase;
          color: #7a7774;
          padding-left: 0.5rem;
        }

        @media screen and (min-width: 1024px) {
          input,
          span {
            height: ${DIGIT_HEIGHT}rem;
            line-height: ${DIGIT_HEIGHT}rem;
            font-size: ${DIGIT_FONT_SIZE}rem;
          }

          @media screen and (min-width: 1024px) {
            :global(input#${BOOKING_COST_ID}::placeholder) {
              font-size: 0.8rem;
            }
          }
      `}</style>
    </div>
  )
}

interface ControlsProps {
  activeIndex: number
  setActiveIndex: Dispatch<SetStateAction<number>>
}

const CarouselControls = ({
  activeIndex,
  setActiveIndex,
}: ControlsProps): JSX.Element => {
  const [swiperKey, setSwiperKey] = useState(0)

  // Reset to first slide at breakpoints
  useCrossBreakpoints({
    breakpoints: [
      640, // There is a Swiper bug where going from a loop to non-looped carousel breaks the slides order. Refresh on this breakpoint as well.
      1024,
    ],
    callback: () => setSwiperKey((prev) => prev + 1),
  })

  return (
    <div className="-mx-4" data-name="carousel-controls">
      <ForaSwiper
        key={`Swiper_${swiperKey}`}
        className={styles.foraSwiper}
        params={{
          pagination: true,
          slidesPerView: 1.2,
          centeredSlides: false,
          keyboard: true,
          breakpoints: {
            640: {
              slidesPerView: 2,
              centeredSlides: true,
              loop: true,
            },
          },
          on: {
            realIndexChange: (swiper) => setActiveIndex(swiper.realIndex),
          },
        }}
      >
        {Commissions.map((slide, index) => (
          <div
            key={`Slide_${index}`}
            className={`block w-full h-full p-2 border transition-colors duration-300 ease-in-out ${
              activeIndex === index
                ? 'border-darkShell bg-sand'
                : 'border-transparent'
            }`}
          >
            <Control slide={slide} width={256} height={191} />
          </div>
        ))}
      </ForaSwiper>
    </div>
  )
}

const TabsControls = ({ setActiveIndex }: ControlsProps): JSX.Element => {
  const [tabsKey, setTabsKey] = useState(0)

  // Reset to first slide at breakpoints
  useCrossBreakpoints({
    breakpoints: [1024],
    callback: () => setTabsKey((prev) => prev + 1),
  })

  return (
    <div data-name="tabs-controls">
      <Tabs key={tabsKey} defaultTabIndex={0}>
        <ul className="flex w-full">
          {Commissions.map((slide, index) => (
            <li key={`Tab_${index}`} className="w-full -ml-px first:ml-0">
              <Tab
                tabIndex={index}
                onSelect={() => setActiveIndex(index)}
                className="flex flex-col w-full h-full p-4 transition-colors duration-300 ease-in-out border border-b-darkShell hover:border-darkShell hover:bg-sand"
                activeClassName="border-darkShell bg-sand"
                inactiveClassName="border-t-transparent border-r-transparent border-l-transparent"
              >
                <Control slide={slide} width={244} height={226} />
              </Tab>
            </li>
          ))}
        </ul>
      </Tabs>
    </div>
  )
}

const Control = ({
  slide,
  width,
  height,
}: {
  slide: Commission
  width: number
  height: number
}): JSX.Element => {
  return (
    <>
      <div
        className="relative w-full"
        style={{ aspectRatio: `${width} / ${height}` }}
        data-name="control-image"
      >
        <Media
          image={slide.image}
          altText={slide.altText}
          width={width}
          height={height}
        />
      </div>
      <p
        className="mt-4 mb-2 text-left lg:mb-0 fora-text-caption-1"
        data-name="control-title"
      >
        {slide.title}
      </p>
    </>
  )
}

function Media({
  image,
  altText,
  width,
  height,
}: {
  image: string
  altText: string
  width: number
  height: number
}): JSX.Element {
  return (
    <Image
      src={image}
      alt={altText}
      layout="fill"
      width={width}
      height={height}
      objectFit="cover"
      objectPosition="center"
      sizes="90vw, (min-width: 640px) 50vw, (min-width: 1024px) 244px"
      priority
    />
  )
}
